exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-como-vender-js": () => import("./../../../src/pages/como-vender.js" /* webpackChunkName: "component---src-pages-como-vender-js" */),
  "component---src-pages-entrega-y-recogida-js": () => import("./../../../src/pages/entrega-y-recogida.js" /* webpackChunkName: "component---src-pages-entrega-y-recogida-js" */),
  "component---src-pages-financiacion-js": () => import("./../../../src/pages/financiacion.js" /* webpackChunkName: "component---src-pages-financiacion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landings-negocio-js": () => import("./../../../src/pages/landings-negocio.js" /* webpackChunkName: "component---src-pages-landings-negocio-js" */),
  "component---src-pages-politicas-y-promociones-js": () => import("./../../../src/pages/politicas-y-promociones.js" /* webpackChunkName: "component---src-pages-politicas-y-promociones-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */)
}

